<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import OrderReciept from "@/components/orderReciept";
import DatePicker from "vue2-datepicker";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    OrderReciept,
    DatePicker,

  },
  data() {
    return {
          tableData: [],
      tableData2: [],
      dashboardData: [],
      tableDataDiscount: [],
      corporates: [],
      corporatebranches: [],
      dobAniversaryChart: [],
      feedbackRatingChart: [],
      title: "Dashboard",
      items: [
        {
          text: "View",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      daterange: [],
      orderData: [],
      orderDetails: [],
      branches: [],
      branchID: "",
      corpBranchID: "",
      restBranchID: this.$storageData.profile.restBranchID,
      cityArr: [],
      city: "",
      countryArr: [],
      country: "",
      brandArr: [],
      brandData: "",
      cardData: [],
      pendingApprovalCardData: [],
      salesGraph: [],
      orderedItemArr: [],
      orderItemAddonArr: [],
      orderStatusArr: [],
      orderStatusOptions: [
        { statusID: "2", statusName: "Pending" },
        { statusID: "3", statusName: "Accepted" },
        { statusID: "4", statusName: "Preparing" },
        { statusID: "5", statusName: "Prepared" },
        { statusID: "6", statusName: "Dispatched" },
        { statusID: "7", statusName: "Delivered" },
        { statusID: "8", statusName: "Cancelled" },
      ],
      paymentType: "",
      paymentTypeArr: ["Online", "Wallet", "Subsidy", "Pre-buy voucher", "COD"],
      orderTypeArr: [
        "Cafeteria / Food Court",
        "Near by Restaurant",
        "Event Catering",
      ],
      orderType: "",
      corporateID: "",
      orderStatus: "",
      companyArr: [],
      // orderDetails:[],
      // orderStatusArr:[],
      // orderedItemArr:[],
      companyID: 0,
      newoption: "",
      value: 0,
      query: false,
      show: true,
      interval: 0,
      isLoading: 0,
      fields: [
        // {
        //     key: "Action",
        //     sortable: false,
        // },
        {
          key: "orderID",
          sortable: true,
        },
        {
          key: "employeeName",
          sortable: true,
        },
        {
          key: "employeeID",
          sortable: true,
        },
        {
          key: "restaurant",
          sortable: true,
        },
        {
          key: "itemTotal",
          sortable: true,
        },
        {
          key: "couponDiscount",
          sortable: true,
        },
        {
          key: "GST",
          sortable: true,
        },
        {
          key: "otherCharges",
          sortable: true,
        },
        {
          key: "orderTotal",
          sortable: true,
        },
        {
          key: "paidAmount",
          sortable: true,
        },

        {
          key: "walletPoints",
          sortable: true,
        },
        {
          key: "voucherDiscount",
          sortable: true,
        },
        {
          key: "paidAmount",
          sortable: true,
        },
        {
          key: "corporateSubsidy",
          sortable: true,
        },
        {
          key: "GSTSubsidy",
          sortable: true,
        },

        {
          key: "PaymentModeName",
          sortable: true,
        },
        {
          key: "orderType",
          sortable: true,
        },
        {
          key: "date",
          sortable: true,
        },
      ],

      fieldsPending: [
        // {
        //     key: "Action",
        //     sortable: false,
        // },
        {
          key: "orderID",
          sortable: true,
        },
        {
          key: "userName",
          sortable: true,
        },
        {
          key: "restBranchName",
          sortable: true,
        },
      ],
      fieldsDiscount: [
        // {
        //   key: "couponCode",

        //   sortable: true,

        // },
        {
          key: "restaurantName",

          sortable: true,
        },
      ],
      pieChartSeries: [],
      piechartCitySeries: [],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    this.totalRows = this.items.length;
    if (this.$storageData.profile.empTypeID == 1 || this.$storageData.profile.empTypeID == 9) {
      this.getAllCountry();
    } else if (
        this.$storageData.profile.empTypeID == 2 ||
        this.$storageData.profile.empTypeID == 11
    ) {
      this.getRestaurantCountry();
    }
    this.getCorporateList();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllCountry() {
      this.countryArr = [];
      this.country = "";
      this.axios
          .post(this.$loggedRole+"/getAllCountry", {
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
          })
          .then((response) => {
            this.countryArr = response.data.data;
            // if (this.$storageData.profile.empTypeID == 1) {
            // }
            if (this.$storageData.profile.empTypeID == 11) {
              this.getRestaurantBrandsByCompanyID();
            } else {
              this.readRestaurantBranchData();
            }
          });
    },
    getRestaurantBrandsByCompanyID() {
      var companyID = this.companyID;
      if (this.$storageData.profile.empTypeID == 11) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
          .post(this.$loggedRole+"/getRestaurantBrandsByCompanyID", {
            companyID: companyID,
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
          })
          .then((response) => {
            this.brandArr = response.data.data;
            // this.brandData = (this.brandArr) ? this.brandArr[0] : "";
            this.readRestaurantBranchData();
          });
    },
    getRestaurantCountry() {
      this.axios
          .post(this.$loggedRole+"/getRestaurantCountry", {
            restID: this.$storageData.profile.restID,
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
          })
          .then((response) => {
            this.countryArr = response.data.data;
            this.country = this.countryArr ? this.countryArr[0] : "";
            this.getAllCompanies();
            this.getRestaurantCities();
          });
    },
    getRestaurantCities() {
      var restID = this.brandData.restID;
      if (this.$storageData.profile.empTypeID == 11) {
        restID = this.$storageData.profile.restID;
      }
      this.axios
          .post(this.$loggedRole+"/getRestaurantCities", {
            restID: restID,
            country: this.country ? this.country.country : "",
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
          })
          .then((response) => {
            this.cityArr = response.data.data;
            this.city = this.cityArr ? this.cityArr[0] : "";
            if (this.$storageData.profile.empTypeID == 11) {
              this.getRestaurantBrandsByCompanyID();
            } else {
              this.readRestaurantBranchData();
            }
          });
    },

    getAllCities() {
      this.cityArr = [];
      this.city = "";
      this.axios
          .post(this.$loggedRole+"/getAllCities", {
            country: this.country ? this.country.country : "",
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
          })
          .then((response) => {
            this.cityArr = response.data.data;
            if (this.$storageData.profile.empTypeID == 11) {
              this.getRestaurantBrandsByCompanyID();
            }
          });
    },
    getAllCompanies() {
      this.companyArr = [];
      this.companyID = "";
      var companyID = 0;
      if (this.$storageData.profile.empTypeID == 11) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
          .post(this.$loggedRole+"/getAllCompanies", {
            companyID: companyID,
            country: this.country ? this.country.country : "",
            city: this.city ? this.city.city : "",
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
          })
          .then((response) => {
            this.companyArr = response.data.data;
            if (this.$storageData.profile.empTypeID == 11) {
              this.companyID = this.companyArr ? this.companyArr[0] : "";
            }
            //  this.getProductMixReport();
          });
    },
    getRestaurantBrands() {
      this.axios
          .post(this.$loggedRole+"/getRestaurantList", {
            restID: this.$storageData.profile.restID,
            restBranchID: this.$storageData.profile.restBranchID,
            empID: this.$storageData.profile.pid,
            empTypeID: this.$storageData.profile.empTypeID,
            loginTypeID: this.$storageData.login_type,
          })
          .then((result) => {
            this.brandArr = result.data.data;
            // this.brandData = (this.brandArr) ? this.brandArr[0] : "";
            this.readRestaurantBranchData();
          });
    },
    readRestaurantBranchData() {
      var restBranchID = 0;
      if (this.$storageData.profile.empTypeID == 6) {
        restBranchID = this.$storageData.profile.restBranchID;
      }
      this.axios
          .post(this.$loggedRole+"/getRestaurantBranchByRestID", {
            restID:
                this.brandData != ""
                    ? this.brandData.restID
                    : this.$storageData.profile.restID,
            restBranchID: restBranchID,
            empID: this.$storageData.profile.pid,
            empTypeID: this.$storageData.profile.empTypeID,
            city: this.city ? this.city.city : "",
            loginTypeID: this.$storageData.login_type,
          })
          .then((response) => {
            this.branches = response.data.data;
            this.branchID = this.branches ? this.branches[0] : "";
            this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
            // this.viewSalesReport();
          });
    },
    onChangeRestBrand() {
      this.readRestaurantBranchData();
    },
    onchangeBranch() {
      this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
      //this.viewSalesReport();
    },
    getCorporateList() {
      this.axios
          .post(this.$loggedRole+"/getCorporateLists", {
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
          })
          .then((result) => {
            this.corporates = result.data.data;
          });
    },

    applyFilter() {
      if (this.daterange.includes(null)) {
        this.daterange = [];
      }
      this.superAdminDashboard();
      this.superAdminDashboardGraph();
    },

    superAdminDashboard() {
      this.isLoading = 1;
      this.axios
          .post(this.$loggedRole+"/adminDashBoard", {
            restID:
                this.brandData != ""
                    ? this.brandData.restID
                    : this.$storageData.profile.restID,
            restBranchID:
                this.branchID != "" && this.branchID != null
                    ? this.branchID.restBranchID
                    : this.$storageData.profile.restBranchID,
            daterange: this.daterange,
            paymentType: this.paymentType,
            orderType: this.orderType,
            corporateID: this.corporateID,
            corpBranchID: this.corpBranchID,
            country: this.country ? this.country.country : "",
            city: this.city ? this.city.city : "",
            empID: this.$storageData.profile.pid,
            empTypeID: this.$storageData.profile.empTypeID,
            loginTypeID: this.$storageData.login_type,
          })
          .then((response) => {
            //Then injecting the result to datatable parameters.
            this.isLoading = 0;
            this.cardData = response.data.cardData;
            this.pendingApprovalCardData = response.data.pendingCardData;

          });
    },

    superAdminDashboardGraph() {
      this.isLoading = 1;
      this.axios
          .post(this.$loggedRole+"/adminDashBoardGraph", {
            restID:
                this.brandData != ""
                    ? this.brandData.restID
                    : this.$storageData.profile.restID,
            restBranchID:
                this.branchID != "" && this.branchID != null
                    ? this.branchID.restBranchID
                    : this.$storageData.profile.restBranchID,
            daterange: this.daterange,
            paymentType: this.paymentType,
            orderType: this.orderType,
            corporateID: this.corporateID,
            corpBranchID: this.corpBranchID,
            empID: this.$storageData.profile.pid,
            empTypeID: this.$storageData.profile.empTypeID,
            loginTypeID: this.$storageData.login_type,
            country: this.country ? this.country.country : "",
            city: this.city ? this.city.city : "",
          })
          .then((response) => {
            //Then injecting the result to datatable parameters.
            this.isLoading = 0;
            this.salesGraph = response.data.salesGraph;
            //  this.dashboardData = response.data.corporateWalletGraph;
            this.walletStatData = response.data.walletStatData;
            this.pieChart = response.data.pieChart;
            this.pieChartSeries = response.data.pieChart.series;
            this.piechartCity = response.data.piechartCity;
            this.piechartCitySeries = response.data.piechartCity.series;
            // //  this.tableDataPending=response.data.pendingorderData;
            this.tableDataDiscount = response.data.tableDataDiscount;
            this.dobAniversaryChart = response.data.dobAniversaryChart;
            this.feedbackRatingChart = response.data.feedbackRatingChart;
          });
    },
    viewOrderDetails(orderID) {
      // alert(orderID);
      this.$root.$emit("bv::show::modal", "modal-lg-orderDetails");
      this.axios
          .post(this.$loggedRole+"/getOrderDetailsByID", { orderID: orderID })
          .then((result) => {
            this.orderDetails = result.data.data;
            this.orderStatusArr = result.data.data.orderStatus;
            //  console.log(result.data.data.orderedItemArr);
            this.orderedItemArr = result.data.data.orderedItemArr;
            // this.orderItemAddonArr = result.data.orderItemAddonArr;
          });
    },
    clearFilter() {
      this.daterange = [];
      this.brandData = "";
      this.restBranchID = "";
      this.country = "";
      this.companyID = "";
      this.brandData = "";
      this.city = "";
      this.cityArr= [];
      this.companyArr=[];
      this.brandArr=[];
      this.branches=[];
      this.branchID = "";
      this.paymentType = "";
      this.orderType = "";
      this.isLoading = 0;
    },

    applyFilter2() {
      this.superAdminDashboard();
      this.superAdminDashboardGraph();
      // this.getProductMixReportByValue(this.productMixTab);
      // this.getProductMixReportByCategory();
    },

    clearFilter2() {
      this.corporateID = "";
      this.corpBranchID = "";
      // this.viewSalesReport();
    },

    getCorporateBranchByCorpID() {
      var corporateID = this.corporateID.corporateID;

      this.axios
          .post(this.$loggedRole+"/getCorporateBranchByCorpID", {
            corporateID: corporateID,
            flag: this.$storageData.profile.empTypeID == 5 ? 0 : 1, // for all dropdown in only dashboards
            city: this.city ? this.city.city : "",
          })
          .then((response) => {
            this.corporatebranches = response.data.data;
            // this.branchID = this.branches[0];
            //this.corpBranchID = this.branchID.corpBranchID;
          });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <PageHeader :items="items" />

    <div class="card" style="margin-top: -30px">
      <div class="card-body">
        <div class="inner mb-3 row">
          <!-- Country List-->
          <!-- v-if="this.$storageData.profile.empTypeID==1 || this.$storageData.profile.empTypeID==2 || this.$storageData.profile.empTypeID==11" -->
          <div class="col-md-2" style="width: 12%">
            <label>Country </label>
            <multiselect
                v-model="country"
                :options="countryArr"
                track-by="country"
                label="country"
                @input="getAllCities()"
                :show-labels="false"
            ></multiselect>
          </div>
          <!-- Country List END-->
          <!-- Cities List-->
          <div class="col-md-2" style="width: 12%">
            <label>City </label>
            <multiselect
                v-model="city"
                :options="cityArr"
                track-by="city"
                label="city"
                @input="getAllCompanies()"
                :show-labels="false"
            ></multiselect>
          </div>
          <!--END Cities List-->
          <!-- Company List-->
          <!-- v-if="this.$storageData.profile.empTypeID==1 || this.$storageData.profile.empTypeID==11" -->
          <div class="col-md-2" style="width: 16%">
            <label>Company</label>
            <multiselect
                v-model="companyID"
                :options="companyArr"
                track-by="companyID"
                label="companyName"
                @input="getRestaurantBrandsByCompanyID()"
                :show-labels="false"
            ></multiselect>
          </div>
          <!-- Compnay List END-->
          <!-- Brand List-->
          <div class="col-md-2" style="width: 16%">
            <label>Restaurant Brand</label>
            <multiselect
                v-model="brandData"
                :options="brandArr"
                track-by="restID"
                label="name"
                @input="readRestaurantBranchData()"
                :show-labels="false"
            ></multiselect>
          </div>
          <!-- Brand List END-->

          <!-- Branch List-->
          <div class="col-md-2" style="width: 16%">
            <label>Restaurant Branch </label>
            <multiselect
                v-model="branchID"
                :options="branches"
                track-by="restBranchID"
                label="branchName"
                @input="onchangeBranch()"
                :show-labels="false"
            ></multiselect>
          </div>
          <!-- Branch List END-->
          <!-- Date range -->
          <div class="col-md-2" style="width: 20%">
            <label>Date Range </label>
            <date-picker
                v-model="daterange"
                format="DD MMM Y"
                range
                append-to-body
                lang="en"
                confirm
                value-type="format"
                @input="onchangeBranch()"
            ></date-picker>
          </div>
          <!-- Date range END-->
          <!-- Payment Type -->
          <!-- <div class="col-md-2 mt-2" style="width: 18%">
            <label>Payment Type </label>
            <multiselect
              v-model="paymentType"
              :options="paymentTypeArr"
              @input="onchangeBranch()"
              :show-labels="false"
            ></multiselect>
          </div> -->
          <!-- Payment Type END-->
          <!-- Order Type -->
          <div class="col-md-2 mt-2" style="width: 18%">
            <label>Order Type </label>
            <multiselect
                v-model="orderType"
                :options="orderTypeArr"
                @input="onchangeBranch()"
                :show-labels="false"
            ></multiselect>
          </div>
          <!-- Order Type END-->
          <!-- <div class="col-md-2"  style="width:18%"> 
                            <label>Select Corporate </label>
                            <multiselect v-model="corporateID" :options="corporates"  track-by="corporateID" label="corporateName"  @input="viewSalesReport();"  ></multiselect>
                               </div> -->

          <div class="col-md-2" style="width: auto; margin-top: 6px">
            <button class="btn btn-themeOrange mt-4" v-on:click="applyFilter()" v-if="$can('admin dashboard access')" >
              Apply Filter
            </button>
          </div>
          <!-- Clear filter -->
          <div class="col-md-2" style="width: 16%; margin-top: 6px">
            <button class="btn btn-themeBrown mt-4" v-on:click="clearFilter()">
              Clear Filter
            </button>
          </div>

          <!-- Clear filter END-->
        </div>
      </div>
    </div>
    <!--FILTER END------>
    <div class="card" style="margin-top: -30px">
      <div class="card-body">
        <div class="inner mb-2 row">
          <div class="col-md-2" style="width: 18%">
            <label>Select Corporate </label>
            <multiselect
                v-model="corporateID"
                :options="corporates"
                @input="getCorporateBranchByCorpID()"
                track-by="corporateID"
                label="corporateName"
            ></multiselect>
          </div>
          <div class="col-md-2" style="width: 18%">
            <label class="form-label">Select Corporate Branch </label>
            <multiselect
                v-model="corpBranchID"
                :options="corporatebranches"
                track-by="corpBranchID"
                label="corpBranchName"
                :show-labels="false"
            ></multiselect>
          </div>

          <!-- <div class="col-md-2" style="width:18%">
              <label class="form-label" for="formrow-endTime-input">Select Corporate Branch </label>
              <multiselect v-model="corpBranchID" :options="corporatebranches" track-by="corpBranchID" label="corpBranchName" @input="readMealPlanData"
                  ></multiselect>
          </div> -->

          <div class="col-md-2" style="width: auto">
            <button
                class="btn btn-themeOrange mt-4"
                v-on:click="applyFilter2()"
            >
              Apply Filter
            </button>
          </div>
          <!-- Clear filter -->
          <div class="col-md-2" style="width: 16%">
            <button class="btn btn-themeBrown mt-4" v-on:click="clearFilter2()">
              Clear Filter
            </button>
          </div>

          <!-- Clear filter END-->
        </div>
      </div>
    </div>
    <!--FILTER END------>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <b-tabs
              justified
              nav-class="nav-tabs-custom"
              content-class="p-3 text-muted"
          >
            <b-tab>
              <div v-if="isLoading == 1" class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading Please wait...</strong>
              </div>
              <!-- <template v-slot:title>
                                <span class="d-inline-block d-sm-none">
                                    <i class="fas fa-home"></i>
                                </span>
                                <span class="d-none d-sm-inline-block">Sales Report Overview</span>
                            </template> -->

              <div class="row" style="margin: 10px 0 0">
                <div class="col-md-6 col-xl-3 overviewCard">
                  <div class="card">
                    <div class="card-body">
                      <div class="float-end mt-2">
                        <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
                      </div>
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{
                            cardData.salesDataOverview
                                ? $func.formatPrice(
                                    cardData.salesDataOverview.sumGrandTotal
                                )
                                : 0
                          }}
                          <!-- <span data-plugin="counterup">
                                  <countTo :startVal="1000" :endVal="(cardData.sumGrandTotal) ? cardData.sumGrandTotal : 0"  :duration="2000"></countTo>
                              </span> -->
                        </h4>
                        <p class="text-muted mb-0">Total</p>
                      </div>
                      <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                          <span>{{
                              cardData.salesDataOverview
                                  ? $func.formatCommaSepNum(
                                      cardData.salesDataOverview.totalOrders
                                  )
                                  : 0
                            }}</span>
                          Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                          <p>
                            {{
                              cardData.salesDataOverview
                                  ? $func.formatPrice(
                                      cardData.salesDataOverview.avgSale
                                  )
                                  : 0
                            }}
                          </p>
                          AOV
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3 overviewCard">
                  <div class="card">
                    <div class="card-body">
                      <div class="float-end mt-2"></div>
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{
                            cardData.salesDataOverview
                                ? $func.formatPrice(
                                    cardData.salesDataOverview
                                        .sumDigitalGrandTotal
                                )
                                : 0
                          }}
                        </h4>
                        <p class="text-muted mb-0">Digital Sales</p>
                      </div>
                      <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                          <span>{{
                              cardData.salesDataOverview
                                  ? $func.formatCommaSepNum(
                                      cardData.salesDataOverview.totalDigitalOrders
                                  )
                                  : 0
                            }}</span>
                          Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                          <p>
                            {{
                              cardData.salesDataOverview
                                  ? $func.formatPrice(
                                      cardData.salesDataOverview.digitalAvgSale
                                  )
                                  : 0
                            }}
                          </p>
                          AOV
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3 overviewCard">
                  <div class="card">
                    <div class="card-body">
                      <div class="float-end mt-2">
                        <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
                      </div>
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{
                            cardData.salesDataOverview
                                ? cardData.salesDataOverview.sumCorpSubsidy !=
                                null
                                    ? $func.formatPrice(
                                        cardData.salesDataOverview.sumCorpSubsidy
                                    )
                                    : 0
                                : 0
                          }}
                        </h4>
                        <p class="text-muted mb-0">Credit Meal Plan</p>
                      </div>
                      <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                          <span>{{
                              cardData.salesDataOverview
                                  ? cardData.salesDataOverview.totalMealOrders !=
                                  null
                                      ? $func.formatCommaSepNum(
                                          cardData.salesDataOverview.totalMealOrders
                                      )
                                      : 0
                                  : 0
                            }}</span>
                          Quantity
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                          <p>
                            {{
                              cardData.salesDataOverview
                                  ? cardData.salesDataOverview.mealPlanAvgSale !=
                                  null
                                      ? $func.formatPrice(
                                          cardData.salesDataOverview.mealPlanAvgSale
                                      )
                                      : 0
                                  : 0
                            }}
                          </p>
                          AOV
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3 overviewCard">
                  <div class="card">
                    <div class="card-body">
                      <div class="float-end mt-2">
                        <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
                      </div>
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{
                            cardData.salesDataOverview
                                ? $func.formatNumber(
                                    cardData.salesDataOverview.sumWallet
                                )
                                : 0
                          }}
                        </h4>
                        <p class="text-muted mb-0">Wallet</p>
                      </div>
                      <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                          <span>{{
                              cardData.salesDataOverview
                                  ? $func.formatCommaSepNum(
                                      cardData.salesDataOverview.totalWalletOrders
                                  )
                                  : 0
                            }}</span>
                          Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                          <p>
                            {{
                              cardData.salesDataOverview
                                  ? $func.formatNumber(
                                      cardData.salesDataOverview.walletAvgSale
                                  )
                                  : 0
                            }}
                          </p>
                          AOV
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3 overviewCard">
                  <div class="card">
                    <div class="card-body">
                      <div class="float-end mt-2">
                        <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
                      </div>
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{
                            cardData.salesDataOverview
                                ? $func.formatPrice(
                                    cardData.salesDataOverview.sumVoucher
                                )
                                : 0
                          }}
                        </h4>
                        <p class="text-muted mb-0">Voucher</p>
                      </div>
                      <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                          <span>{{
                              cardData.salesDataOverview
                                  ? $func.formatCommaSepNum(
                                      cardData.salesDataOverview.totalVouchersOrders
                                  )
                                  : 0
                            }}</span>
                          Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                          <p>
                            {{
                              cardData.salesDataOverview
                                  ? $func.formatPrice(
                                      cardData.salesDataOverview.voucherAvgSale
                                  )
                                  : 0
                            }}
                          </p>
                          AOV
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3 overviewCard">
                  <div class="card">
                    <div class="card-body">
                      <div class="float-end mt-2">
                        <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
                      </div>
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{
                            cardData.salesDataOverview
                                ? $func.formatPrice(
                                    cardData.salesDataOverview.sumCoupon
                                )
                                : 0
                          }}
                        </h4>
                        <p class="text-muted mb-0">Coupon</p>
                      </div>
                      <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                          <span>{{
                              cardData.salesDataOverview
                                  ? $func.formatCommaSepNum(
                                      cardData.salesDataOverview.totalCouponsOrders
                                  )
                                  : 0
                            }}</span>
                          Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                          <p>
                            {{
                              cardData.salesDataOverview
                                  ? $func.formatPrice(
                                      cardData.salesDataOverview.couponAvgSale
                                  )
                                  : 0
                            }}
                          </p>
                          AOV
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-xl-3 overviewCard">
                  <div class="card" style="height: 94%">
                    <div class="card-body">
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{
                            cardData.salesDataOverview
                                ? $func.formatPrice(
                                    cardData.salesDataOverview.sumVisitorQrOrders
                                )
                                : 0
                          }}
                        </h4>
                        <p class="text-muted mb-0">Visitor Orders</p>
                      </div>
                      <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                          <span>{{
                              $func.formatCommaSepNum(
                                  cardData.salesDataOverview
                                      ? cardData.salesDataOverview
                                          .totalVisitorQrOrders
                                      : 0
                              )
                            }}</span>
                          Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                          <p>
                            {{
                              cardData.salesDataOverview
                                  ? $func.formatPrice(
                                      cardData.salesDataOverview.visitorAvgSale
                                  )
                                  : 0
                            }}
                          </p>
                          AOV
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-xl-3 overviewCard">
                  <div class="card">
                    <div class="card-body">
                      <div class="float-end mt-2">
                        <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
                      </div>
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{
                            cardData.salesDataOverview
                                ? $func.formatPrice(
                                    cardData.salesDataOverview.sumTaxes
                                )
                                : 0
                          }}
                        </h4>
                        <p class="text-muted mb-0">GST</p>
                      </div>
                      <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                          <span>{{
                              cardData.salesDataOverview
                                  ? $func.formatCommaSepNum(
                                      cardData.salesDataOverview.taxOrders
                                  )
                                  : 0
                            }}</span>
                          Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                          <p>
                            {{
                              cardData.salesDataOverview
                                  ? $func.formatPrice(
                                      cardData.salesDataOverview.avgTaxes
                                  )
                                  : 0
                            }}
                          </p>
                          AOV
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3 overviewCard">
                  <div class="card">
                    <div class="card-body">
                      <div class="float-end mt-2">
                        <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
                      </div>
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{
                            cardData.salesDataOverview
                                ? $func.formatPrice(
                                    cardData.salesDataOverview
                                        .sumRejectedGrandTotal
                                )
                                : 0
                          }}
                        </h4>
                        <p class="text-muted mb-0">Rejected Orders</p>
                      </div>
                      <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                          <span>{{
                              cardData.salesDataOverview
                                  ? $func.formatCommaSepNum(
                                      cardData.salesDataOverview.totalRejectedOrders
                                  )
                                  : 0
                            }}</span>
                          Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                          <p>
                            {{
                              cardData.salesDataOverview
                                  ? $func.formatPrice(
                                      cardData.salesDataOverview.rejectedAvgSale
                                  )
                                  : 0
                            }}
                          </p>
                          AOV
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-xl-3 overviewCard">
                  <div class="card">
                    <div class="card-body">
                      <div class="float-end mt-2">
                        <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
                      </div>
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{
                            cardData.feedback
                                ? cardData.feedback.totalFeedbacks
                                : 0
                          }}
                        </h4>
                        <p class="text-muted mb-0">Total Feedbacks</p>
                      </div>
                      <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                          <span>{{
                              cardData.feedback
                                  ? cardData.feedback.totalPositiveFeedbacks
                                  : 0
                            }}</span>
                          Positive
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                          <p>
                            {{
                              cardData.feedback
                                  ? cardData.feedback.totalNegativeFeedbacks
                                  : 0
                            }}
                          </p>
                          Negative
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3 overviewCard">
                  <div class="card">
                    <div class="card-body">
                      <div class="float-end mt-2">
                        <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
                      </div>
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{
                            cardData.totalActiveOnboardedUsers
                                ? cardData.totalActiveOnboardedUsers
                                : 0
                          }}
                        </h4>
                        <p class="text-muted mb-0">Total Users</p>
                      </div>
                      <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                          <span>{{
                              cardData.totalActiveCorporateUsers
                                  ? cardData.totalActiveCorporateUsers
                                  : 0
                            }}</span>
                          Corp Users
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                          <p>
                            {{ cardData.otherUsers ? cardData.otherUsers : 0 }}
                          </p>
                          Others
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3 overviewCard">
                  <div class="card">
                    <div class="card-body">
                      <div class="float-end mt-2">
                        <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
                      </div>
                      <div>
                        <h4 class="cardTitle mb-1 mt-1" id="fspHover">
                          {{
                            cardData.totalServiceProviders
                                ? cardData.totalServiceProviders
                                : 0
                          }}/{{
                            cardData.getTotalServiceProvidersBrand
                                ? cardData.getTotalServiceProvidersBrand
                                : 0
                          }}
                        </h4>
                        <p class="text-muted mb-0">Empanelled Partners</p>
                      </div>
                      <div class="row">
                        <p
                            class="text-muted mt-3 mb-0 col-7 cardSubtitle1"
                            id="prinHover"
                        >
                          <span>
                            {{
                              cardData.totalActivePrinciBranches
                                  ? cardData.totalActivePrinciBranches
                                  : 0
                            }}/{{
                              cardData.totalActivePrincipal
                                  ? cardData.totalActivePrincipal
                                  : 0
                            }}
                          </span>
                          Principal
                        </p>
                        <span
                            class="align-center col-5 cardSubtitle2"
                            id="corpHover"
                        >
                          <p>
                            {{
                              cardData.totalActiveCorpBranches
                                  ? cardData.totalActiveCorpBranches
                                  : 0
                            }}/{{
                              cardData.totalActiveCorp
                                  ? cardData.totalActiveCorp
                                  : 0
                            }}
                          </p>
                          Corporate
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Tooltips for Empanelled Partner -->
                <b-tooltip target="fspHover" triggers="hover">
                  <div class="row">
                    <div class="col-12 align-left mb_5">
                      <div class="row">
                        <div class="col-8">
                          <span>Empanelled Branches: </span>
                        </div>
                        <div class="col-4 align-right">
                          <span>{{
                              cardData.totalServiceProviders
                                  ? cardData.totalServiceProviders
                                  : 0
                            }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-8">
                          <span>Empanelled Brands: </span>
                        </div>
                        <div class="col-4 align-right">
                          <span>{{
                              cardData.getTotalServiceProvidersBrand
                                  ? cardData.getTotalServiceProvidersBrand
                                  : 0
                            }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-8"></div>
                    <div class="col-4"></div>
                  </div>
                </b-tooltip>

                <!-- Tooltips for Pricipal -->
                <b-tooltip target="prinHover" triggers="hover">
                  <div class="row">
                    <div class="col-12 align-left mb_5">
                      <div class="row">
                        <div class="col-8">
                          <span>Principal Branches: </span>
                        </div>
                        <div class="col-4 align-right">
                          <span>{{
                              cardData.totalActivePrinciBranches
                                  ? cardData.totalActivePrinciBranches
                                  : 0
                            }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-8">
                          <span>Principals: </span>
                        </div>
                        <div class="col-4 align-right">
                          <span>{{
                              cardData.totalActivePrincipal
                                  ? cardData.totalActivePrincipal
                                  : 0
                            }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-8"></div>
                    <div class="col-4"></div>
                  </div>
                </b-tooltip>

                <!-- Tooltips for Corporate -->
                <b-tooltip target="corpHover" triggers="hover">
                  <div class="row">
                    <div class="col-12 align-left mb_5">
                      <div class="row">
                        <div class="col-8">
                          <span>Corporate Branches: </span>
                        </div>
                        <div class="col-4 align-right">
                          <span>{{
                              cardData.totalActiveCorpBranches
                                  ? cardData.totalActiveCorpBranches
                                  : 0
                            }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-8">
                          <span>Corporates: </span>
                        </div>
                        <div class="col-4 align-right">
                          <span>{{
                              cardData.totalActiveCorp
                                  ? cardData.totalActiveCorp
                                  : 0
                            }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-8"></div>
                    <div class="col-4"></div>
                  </div>
                </b-tooltip>

                <!-- <div class="col-md-6 col-xl-3 overviewCard">
                  <div class="card" style="height: 94%">
                    <div class="card-body">
                      <div>
                        <h4 class="mb-1 mt-1">
                          {{
                            cardData.salesDataOverview
                              ? cardData.salesDataOverview.sumNetPay
                              : 0
                          }}
                        </h4>
                        <p class="text-muted mb-0">Amount Collected</p>
                      </div>
                    </div>
                  </div>
                </div> -->

              </div>

              <div class="row">
                <div v-if="this.$storageData.profile.empTypeID == 1" class="col-lg-12">
                  <div class="card">
                    <div class="card-body">
                      <h6>Pending Approvals</h6>
                      <div class="row mt-4">
                        <div class="col-md-3 col-xl-3">
                          <div class="card">
                            <div class="card-body">
                              <div>
                                <h4 class="mb-1 mt-1">
                                  {{
                                    pendingApprovalCardData.pendingRestaurants
                                  }}
                                  <!-- <span data-plugin="counterup">
                                      <countTo :startVal="1000" :endVal="voucherData.totalAmountRedeemed" :duration="2000"></countTo>
                                  </span> -->
                                </h4>
                                <p class="text-muted mb-0">Total Restaurants</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 col-xl-3">
                          <div class="card">
                            <div class="card-body">
                              <div>
                                <h4 class="mb-1 mt-1">
                                  {{
                                    pendingApprovalCardData.pendingCorporates
                                  }}
                                  <!-- <span data-plugin="counterup">
                                      <countTo :startVal="1000" :endVal="voucherData.totalAmountRedeemed" :duration="2000"></countTo>
                                  </span> -->
                                </h4>
                                <p class="text-muted mb-0">Total Corporates</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 col-xl-3">
                          <div class="card">
                            <div class="card-body">
                              <div>
                                <h4 class="mb-1 mt-1">
                                  {{ pendingApprovalCardData.pendingprinciple }}
                                  <!-- <span data-plugin="counterup">
                                      <countTo :startVal="1000" :endVal="voucherData.totalAmountRedeemed" :duration="2000"></countTo>
                                  </span> -->
                                </h4>
                                <p class="text-muted mb-0">Total Principals</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 col-xl-3">
                          <div class="card">
                            <div class="card-body">
                              <div>
                                <h4 class="mb-1 mt-1">
                                  {{ pendingApprovalCardData.pendingfoodMenu }}
                                  <!-- <span data-plugin="counterup">
                                      <countTo :startVal="1000" :endVal="voucherData.totalAmountRedeemed" :duration="2000"></countTo>
                                  </span> -->
                                </h4>
                                <p class="text-muted mb-0">Total Food Menus</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Pending approvals -->
                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-body">
                      <h6>Sales Graph</h6>
                      <!-- Bar Chart -->
                      <apexchart
                          v-if="salesGraph.salesGraph"
                          type="area"
                          class="apex-charts"
                          dir="ltr"
                          height="339"
                          :options="salesGraph.salesGraph.chartOptions"
                          :series="salesGraph.salesGraph.series"
                      ></apexchart>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>Top Buying Customers</h6>
                      <!-- Bar chart -->
                      <!-- <apexchart type="line" class="apex-charts" dir="ltr" height="339" :options="salesGraph.topBuyingCustomers.chartOptions" :series="salesGraph.topBuyingCustomers.series"></apexchart> -->
                      <apexchart
                          v-if="salesGraph.topBuyingCustomers"
                          class="apex-charts"
                          height="350"
                          type="bar"
                          dir="ltr"
                          :series="salesGraph.topBuyingCustomers.series"
                          :options="salesGraph.topBuyingCustomers.chartOptions"
                      ></apexchart>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>Top Selling Restaurant Branches</h6>
                      <!-- Bar chart -->
                      <!-- <apexchart type="line" class="apex-charts" dir="ltr" height="339" :options="salesGraph.topSellingRestaurants.chartOptions" :series="salesGraph.topSellingRestaurants.series"></apexchart> -->
                      <apexchart
                          v-if="salesGraph.topSellingRestaunrants"
                          class="apex-charts"
                          height="350"
                          type="bar"
                          dir="ltr"
                          :series="salesGraph.topSellingRestaunrants.series"
                          :options="
                          salesGraph.topSellingRestaunrants.chartOptions
                        "
                      ></apexchart>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>Sales By Companies</h6>
                      <!-- Bar chart -->
                      <!-- <apexchart type="line" class="apex-charts" dir="ltr" height="339" :options="salesGraph.topBuyingCustomers.chartOptions" :series="salesGraph.topBuyingCustomers.series"></apexchart> -->
                      <apexchart
                          v-if="salesGraph.salesByCompanies"
                          class="apex-charts"
                          height="350"
                          type="bar"
                          dir="ltr"
                          :series="salesGraph.salesByCompanies.series"
                          :options="salesGraph.salesByCompanies.chartOptions"
                      ></apexchart>
                    </div>
                  </div>
                </div>
                <!-- salesByCompanies end -->

                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>Sales By Principal Branches</h6>
                      <!-- Bar chart -->
                      <!-- <apexchart type="line" class="apex-charts" dir="ltr" height="339" :options="salesGraph.topBuyingCustomers.chartOptions" :series="salesGraph.topBuyingCustomers.series"></apexchart> -->
                      <apexchart
                          v-if="salesGraph.salesByPrinciples"
                          class="apex-charts"
                          height="350"
                          type="bar"
                          dir="ltr"
                          :series="salesGraph.salesByPrinciples.series"
                          :options="salesGraph.salesByPrinciples.chartOptions"
                      ></apexchart>
                    </div>
                  </div>
                </div>
                <!-- salesByPrinciple end -->

                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-body">
                      <h6>Wallet Graph</h6>
                      <!-- Bar Chart -->
                      <!-- <apexchart type="line" class="apex-charts" dir="ltr" height="339" :options="salesGraph.salesGraph.chartOptions" :series="salesGraph.salesGraph.series"></apexchart>
           -->

                      <apexchart
                          v-if="walletStatData"
                          class="apex-charts"
                          height="339"
                          type="area"
                          dir="ltr"
                          :series="walletStatData.series"
                          :options="walletStatData.chartOptions"
                      ></apexchart>

                      <!-- <v-frappe-chart
                            type="line"
                            :labels="dashboardData.walletStatData.series"
                            :data="dashboardData.walletStatData.chartOptions"
                        /> -->
                      <!-- Spline Area chart -->
                      <!-- <apexchart
                        class="apex-charts"
                        height="350"
                        type="line"
                        dir="ltr"
                        :series="dashboardData.walletStatData.series"
                        :options="dashboardData.walletStatData.chartOptions"
                        ></apexchart>  -->
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>Order Type</h6>
                      <!-- {{pieChart.chartOptions}} 
                      {{pieChart.series}} -->
                      <div id="chart">
                        <div v-if="!pieChartSeries.length == 0">
                          <apexchart
                              class="apex-charts"
                              height="320"
                              type="pie"
                              dir="ltr"
                              :series="pieChart.series"
                              :options="pieChart.chartOptions"
                          ></apexchart>
                        </div>
                        <div v-else style="height: 320px"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>City Wise Sales</h6>
                      <div id="chart">
                        <div v-if="!piechartCitySeries.length == 0">
                          <apexchart
                              class="apex-charts"
                              height="320"
                              type="pie"
                              dir="ltr"
                              :series="piechartCity.series"
                              :options="piechartCity.chartOptions"
                          ></apexchart>
                        </div>
                        <div v-else style="height: 320px"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- top 5 pending orders -->
                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-body">
                      <h6>Top Discounts</h6>
                      <div class="row mt-4"></div>
                      <!-- Table -->
                      <div class="table-responsive mb-0">
                        <b-table
                            striped
                            hover
                            outlined
                            bordered
                            :items="tableDataDiscount"
                            :fields="fieldsDiscount"
                            responsive
                            :per-page="perPage"
                            :current-page="currentPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :filter="filter"
                            :filter-included-fields="filterOn"
                            @filtered="onFiltered"
                        >
                        </b-table>
                      </div>
                    </div>
                  </div>
                </div>
                <!--  top 10 discounts -->

                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>Feedback Ratings</h6>
                      <apexchart
                          v-if="feedbackRatingChart"
                          class="apex-charts"
                          height="350"
                          type="area"
                          :series="feedbackRatingChart.series"
                          :options="feedbackRatingChart.chartOptions"
                      ></apexchart>
                    </div>
                  </div>
                </div>

                <!-- feedback Graph -->

                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>Date Of Birth &#38; Anniversary</h6>
                      <apexchart
                          v-if="dobAniversaryChart"
                          class="apex-charts"
                          height="350"
                          type="bar"
                          dir="ltr"
                          :series="dobAniversaryChart.series"
                          :options="dobAniversaryChart.chartOptions"
                      ></apexchart>
                    </div>
                  </div>
                </div>
                <!-- Date of birth anniversary  -->
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>

    <!-- modals -->
    <OrderReciept
        :orderDetails="this.orderDetails"
        :orderedItemArr="this.orderedItemArr"
        :orderStatusArr="this.orderStatusArr"
    >
    </OrderReciept>
  </Layout>
</template>

<style>
.cardBody {
  min-width: 180px !important;
  width: fit-content !important;
}
.cardTitle {
  font-size: 18px;
}
.cardSubtitle1 span {
  color: #a4827d;
  font-weight: 500;
  /* font-size: 15.5px; */
}

.cardSubtitle1 {
  padding-right: 1px !important;
}

.cardSubtitle2 p {
  color: #f3766a;
  font-weight: 500;
  /* font-size: 17px; */
  margin: 0;
}

.cardSubtitle2 {
  padding-left: 1px !important;
}

.overviewCard {
  padding: 0 5px !important;
  max-width: 240px;
}

.tooltip-inner {
  max-width: 250px !important;
}
</style>
